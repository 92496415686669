import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: true,
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="17.7" height="15.03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.7 15.03"><defs><style>.cls-1{fill:#135eab;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M17.62,7.06a1.22,1.22,0,0,0-.26-.38L11,.33a1.2,1.2,0,0,0-1.65,0A1.17,1.17,0,0,0,9,1.16,1.13,1.13,0,0,0,9.36,2l4.35,4.36H1.17a1.17,1.17,0,0,0,0,2.34H13.71L9.36,13a1.17,1.17,0,0,0-.34.83,1.13,1.13,0,0,0,.34.82,1.12,1.12,0,0,0,.82.35h0a1.12,1.12,0,0,0,.82-.35l6.35-6.35A1.18,1.18,0,0,0,17.62,8h0A1.24,1.24,0,0,0,17.62,7.06Zm-.47.7Z"/></g></g></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="17.7" height="15.03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.7 15.03"><defs><style>.cls-1{fill:#135eab;}</style></defs><g><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M16.53,6.34H4L8.34,2a1.14,1.14,0,0,0,.35-.83A1.17,1.17,0,0,0,8.35.33a1.21,1.21,0,0,0-1.66,0L.35,6.68a1.22,1.22,0,0,0-.26.38A1.15,1.15,0,0,0,.09,8h0a1.19,1.19,0,0,0,.25.38l6.35,6.34a1.16,1.16,0,0,0,1.65,0,1.14,1.14,0,0,0,.35-.83A1.17,1.17,0,0,0,8.35,13L4,8.68H16.53a1.17,1.17,0,0,0,0-2.34Z"/></g></g></svg></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: false,
            dots: true,
          }
        }
      ]
    });
  }
}
